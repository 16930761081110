import React, { useEffect, useRef, useState } from "react";
import {
    Box,
    CircularProgress,
    Container,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    InputAdornment,
    Paper,
    Radio,
    RadioGroup,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    tableCellClasses,
    CardMedia,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import SelectField from "components/Select";
import { PrimaryButton } from "components/Buttons";
import Colors from "assets/Style/Colors";
import { FontFamily } from "assets";
import { ErrorToaster, SuccessToaster } from "components/Toaster";
import InputField from "components/Input";
import SystemServices from "services/System";
import BankServices from "services/Bank";
import FinanceServices from "services/Finance";
import { QRCodeCanvas } from "qrcode.react";
import DatePicker from "components/DatePicker";
import { GeneratePDF, handleExportWithComponent, numberRegex } from "utils";
import { Add, Delete } from "@mui/icons-material";
import styled from "@emotion/styled";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import { useReactToPrint } from "react-to-print";
import { PDFExport } from "@progress/kendo-react-pdf";
import LogoImage from "assets/Images/header_voucher.svg";
import {
    Images,
    InvoiceGlobal,
    InvoiceLocation,
    InvoiceMail,
    InvoicePhone,
} from "assets";
import { CommaSeparator } from "utils";
// *For Table Style
const Row = styled(TableRow)(({ theme }) => ({
    border: 0,
}));

const Cell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        fontSize: 14,
        fontFamily: FontFamily.openSans,
        border: 0,
        textAlign: "center",
        whiteSpace: "nowrap",
        background: Colors.primary,
        color: Colors.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        fontFamily: FontFamily.openSans,
        textAlign: "center",
        textWrap: "nowrap",
        padding: "5px !important",

        ".MuiBox-root": {
            display: "flex",
            gap: "6px",
            alignItems: "center",
            justifyContent: "center",
            ".MuiBox-root": {
                cursor: "pointer",
            },
        },
        svg: {
            width: "auto",
            height: "24px",
        },
        ".MuiTypography-root": {
            textTransform: "capitalize",
            fontFamily: FontFamily.openSans,
            textWrap: "nowrap",
        },
        ".MuiButtonBase-root": {
            padding: "8px",
            width: "28px",
            height: "28px",
        },
    },
}));

const useStyle = makeStyles({
    text: {
        color: Colors.smokeyGrey,
        fontWeight: 300,
        fontFamily: FontFamily.openSans,
    },
});

function TopUpVoucher() {
    const { id } = useParams();
    const classes = useStyle();
    const contentRef = useRef(null);

    const [loader, setLoader] = useState(false);

    // *For Vault Top Up Detail
    const [vaultTopUpDetail, setVaultTopUpDetail] = useState();

    const [fcyAed, setFcyAed] = useState(0);
    const [fcyUsd, setFcyUsd] = useState(0);
    const [lcyAed, setLcyAed] = useState(0);
    const [lcyUsd, setLcyUsd] = useState(0);

    // *For Currency
    const [exchangeRateAed, setExchangeRateAed] = useState();
    const [exchangeRateUsd, setExchangeRateUsd] = useState();
    const [updateExchangeRate, setUpdatedExchangeRate] = useState(0)
    const [UsdexchangeRate, setUsdExchangeRate] = useState(0);
    const [receivedExRate, setReceivedExRate] = useState(0)

    // *For Get Vault Top Up Detail
    const getVaultTopUpDetail = async () => {
        setLoader(true);
        try {
            let params = {
                topup_id: atob(id),
            };
            const { data } = await FinanceServices.getVaultTopUpDetail(params);
            setVaultTopUpDetail(data?.topup);
            let fcyAed = 0;
            let fcyUsd = 0;
            let lcyAed = 0;
            let lcyUsd = 0;
            let rateAed = 0;
            let rateUsd = 0;
            let updatedRateUsd = 0;
            let receivedRate = 0;
            data?.topup?.details.forEach((e) => {
                if (e?.currency === "usd") {
                    fcyAed += parseFloat(e?.fcy_amount);
                    lcyAed += parseFloat(e?.lcy_amount);
                    rateAed = parseFloat(e?.ex_rate);
                    updatedRateUsd = parseFloat(e?.updated_ex_rate);
                    receivedRate = parseFloat(e?.received_ex_rate)
                } else {
                    fcyUsd += parseFloat(e?.fcy_amount);
                    lcyUsd += parseFloat(e?.lcy_amount);
                    rateUsd = parseFloat(e?.ex_rate);
                    updatedRateUsd = parseFloat(e?.updated_ex_rate);
                    receivedRate = parseFloat(e?.received_ex_rate)
                }
            });
            setFcyAed(fcyAed);
            setFcyUsd(fcyUsd);
            setLcyAed(lcyAed);
            setLcyUsd(lcyUsd);
            setExchangeRateAed(rateAed);
            setExchangeRateUsd(rateUsd);
            setUpdatedExchangeRate(updatedRateUsd);
            setReceivedExRate(receivedRate)
        } catch (error) {
            ErrorToaster(error);
        } finally {
            setLoader(false);
        }
    };
    const getCurrencies = async () => {
        try {
            let params = {
                detailed: true,
            };
            const { data } = await SystemServices.getCurrencies(params);

            setUsdExchangeRate(
                data?.currencies[2]?.conversion_rate
            );
        } catch (error) {
            ErrorToaster(error);
        }
    };
    useEffect(() => {
        getCurrencies()
        if (id) {
            getVaultTopUpDetail();
        }
    }, [id]);

    return (
        <Container>
            {!loader && (
                <Box sx={{ textAlign: "right", p: 4 }}>
                    <PrimaryButton
                        title="Download Wallet"
                        type="button"
                        style={{ backgroundColor: Colors.bluishCyan }}
                        onClick={() => handleExportWithComponent(contentRef)}
                    />
                </Box>
            )}
            <PDFExport ref={contentRef} fileName="Wallet Top UP">
                <Box
                    sx={{
                        width: "1000px",
                        mx: 4,
                        my: 2,
                        bgcolor: Colors.white,
                        boxShadow: "0px 8px 18px 0px #9B9B9B1A",
                    }}
                >
                    <Grid container spacing={0}>
                        <Grid item xs={3.5} sm={3.5} md={3.5} textAlign={"center"}>
                            <Box
                                component={"img"}
                                src={LogoImage}
                                sx={{
                                    width: "100%",
                                    textAlign: "center",
                                }}
                            />
                        </Grid>
                        <Grid item xs={8.5} sm={8.5} md={8.5}>
                            <CardMedia image={Images.invoiceHeader} sx={{ mb: 2 }}>
                                <Typography
                                    variant="h3"
                                    sx={{ py: 3, textAlign: "center", color: Colors.white }}
                                >
                                    Galaxy Used Cars Tr. LLC
                                </Typography>
                            </CardMedia>
                            <Grid
                                container
                                spacing={1.5}
                                alignItems={"center"}
                                justifyContent={"space-evenly"}
                            >
                                <Grid item xs={4} sm={4} md={4}>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            gap: "5px",
                                            alignItems: "center",
                                        }}
                                    >
                                        <InvoicePhone />
                                        <Typography
                                            variant="body1"
                                            sx={{
                                                color: Colors.smokeyGrey,
                                                fontFamily: FontFamily.openSans,
                                            }}
                                        >
                                            +971 6 510 2000
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={6} sm={6} md={6}>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            gap: "5px",
                                            alignItems: "center",
                                        }}
                                    >
                                        <InvoiceMail />
                                        <Typography
                                            variant="body1"
                                            sx={{
                                                color: Colors.smokeyGrey,
                                                fontFamily: FontFamily.openSans,
                                            }}
                                        >
                                            info@galaxyshipping.com
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={4} sm={4} md={4}>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            gap: "5px",
                                            alignItems: "center",
                                        }}
                                    >
                                        <InvoiceGlobal />
                                        <Typography
                                            variant="body1"
                                            sx={{
                                                color: Colors.smokeyGrey,
                                                fontFamily: FontFamily.openSans,
                                            }}
                                        >
                                            https://galaxyshipping.com
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={6} sm={6} md={6}>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            gap: "5px",
                                            alignItems: "center",
                                        }}
                                    >
                                        <InvoiceLocation />
                                        <Typography
                                            variant="body1"
                                            sx={{
                                                color: Colors.smokeyGrey,
                                                fontFamily: FontFamily.openSans,
                                            }}
                                        >
                                            Ind Area#4 P.O Box 83126, Sharjah , UAE
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    {loader ? (
                        <Box sx={{ textAlign: "center", py: 3 }}>
                            <CircularProgress />
                        </Box>
                    ) : (
                        <Box sx={{ p: 5 }}>
                            <Grid container spacing={2} alignItems="flex-start">
                                <Grid item xs={12} sm={9}>
                                    <Typography
                                        variant="h5"
                                        sx={{
                                            color: Colors.charcoalGrey,
                                            fontFamily: FontFamily.openSans,
                                            mb: 4,
                                        }}
                                    >
                                        Wallet Top UP
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <FormControl>
                                        <RadioGroup row value={vaultTopUpDetail?.payment_medium}>
                                            <FormControlLabel
                                                value="cash"
                                                control={<Radio />}
                                                label="Cash"
                                            />
                                            <FormControlLabel
                                                value="bank"
                                                control={<Radio />}
                                                label="Bank"
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                                <Grid container spacing={2} item xs={12} sm={6}>
                                    <Grid item md={5}>
                                        <Typography variant="body1">Received Form:</Typography>
                                    </Grid>
                                    <Grid item md={6}>
                                        <Typography
                                            variant="body1"
                                            className={classes.text}
                                            sx={{
                                                wordBreak: "break-word",
                                                whiteSpace: "normal",
                                            }}
                                        >
                                            {vaultTopUpDetail?.receiver?.name ?? "-"}
                                        </Typography>
                                    </Grid>

                                    <Grid item md={5}>
                                        <Typography variant="body1">Wallet Account:</Typography>
                                    </Grid>
                                    <Grid item md={6}>
                                        <Typography variant="body1" className={classes.text}
                                            sx={{
                                                wordBreak: "break-word",
                                                whiteSpace: "normal",
                                            }}>
                                            {vaultTopUpDetail?.vault?.name ?? "-"}
                                        </Typography>
                                    </Grid>
                                    <Grid item md={5}>
                                        <Typography variant="body1">Remark:</Typography>
                                    </Grid>
                                    <Grid item md={6}>
                                        <Typography variant="body1" className={classes.text}>
                                            {vaultTopUpDetail?.remarks ?? "-"}
                                        </Typography>
                                    </Grid>
                                    <Grid item md={5}>
                                        <Typography variant="body1">Received By:</Typography>
                                    </Grid>
                                    <Grid item md={6}>
                                        <Typography noWrap variant="body1" className={classes.text}>
                                            {vaultTopUpDetail?.received_by ?? "-"}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2} item xs={12} sm={6}>
                                    <Grid item md={5}>
                                        <Typography variant="body1">Processing Date:</Typography>
                                    </Grid>
                                    <Grid item md={6}>
                                        <Typography noWrap variant="body1" className={classes.text}>
                                            {moment(vaultTopUpDetail?.created_at).format(
                                                "DD/MM/YYYY"
                                            )}
                                        </Typography>
                                    </Grid>
                                    <Grid item md={5}>
                                        <Typography variant="body1">Customize Date:</Typography>
                                    </Grid>
                                    <Grid item md={6}>
                                        <Typography noWrap variant="body1" className={classes.text}>
                                            {moment(vaultTopUpDetail?.c_date).format(
                                                "DD/MM/YYYY"
                                            )}
                                        </Typography>
                                    </Grid>
                                    <Grid item md={5}>
                                        <Typography variant="body1">Receipt Voucher #:</Typography>
                                    </Grid>
                                    <Grid item md={6}>
                                        <Typography noWrap variant="body1" className={classes.text}>
                                            GRV-{vaultTopUpDetail?.id}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid
                                container
                                spacing={2}
                                mt={4}
                                justifyContent={"space-between"}
                            >
                                <Grid item md={6}>
                                    <Box>
                                        <Typography variant="subtitle1">Amount Received</Typography>
                                    </Box>
                                    <TableContainer
                                        component={Paper}
                                        sx={{
                                            boxShadow: "0px 8px 18px 0px #9B9B9B1A",
                                            borderRadius: 2,
                                        }}
                                    >
                                        <Table stickyHeader>
                                            <TableHead>
                                                <TableRow>
                                                    <Cell>AED</Cell>
                                                    <Cell>Ex. Rate</Cell>
                                                    <Cell>USD</Cell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <Row>
                                                    <Cell>
                                                        {CommaSeparator(parseFloat(
                                                            vaultTopUpDetail?.total_amount_aed
                                                        )?.toFixed(2))}
                                                    </Cell>
                                                    <Cell>{receivedExRate ? receivedExRate?.toFixed(3) : UsdexchangeRate}</Cell>
                                                    <Cell>
                                                        {CommaSeparator(parseFloat(
                                                            vaultTopUpDetail?.total_amount_aed / receivedExRate
                                                        )?.toFixed(2))}
                                                    </Cell>
                                                </Row>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                                <Grid item md={6}>
                                    <Box>
                                        <Typography variant="subtitle1">
                                            Received Currency Detail
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <TableContainer
                                            component={Paper}
                                            sx={{
                                                boxShadow: "0px 8px 18px 0px #9B9B9B1A",
                                                borderRadius: 2,
                                            }}
                                        >
                                            <Table stickyHeader>
                                                <TableHead>
                                                    <TableRow>
                                                        <Cell>Currency</Cell>
                                                        <Cell>FCY Amt</Cell>
                                                        <Cell>Ex.Rate</Cell>
                                                        <Cell>LCY</Cell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    <Row>
                                                        <Cell>USD</Cell>
                                                        <Cell>{CommaSeparator(fcyAed?.toFixed(2))}</Cell>
                                                        <Cell>{updateExchangeRate ? updateExchangeRate?.toFixed(3) : exchangeRateAed}</Cell>
                                                        <Cell>{CommaSeparator(lcyAed?.toFixed(2))}</Cell>
                                                    </Row>
                                                    <Row>
                                                        <Cell>AED</Cell>
                                                        <Cell>{CommaSeparator(fcyUsd?.toFixed(2))}</Cell>
                                                        <Cell>{exchangeRateUsd?.toFixed(2)}</Cell>
                                                        <Cell>{CommaSeparator(lcyUsd?.toFixed(2))}</Cell>
                                                    </Row>
                                                    <Row>
                                                        <Cell colspan={2}>
                                                            <Typography
                                                                variant="body1"
                                                                sx={{ fontWeight: 700 }}
                                                            >
                                                                Total in AED
                                                            </Typography>
                                                        </Cell>
                                                        <Cell colspan={2}>
                                                            <Typography
                                                                variant="body1"
                                                                sx={{ fontWeight: 700 }}
                                                            >
                                                                {CommaSeparator(parseFloat(
                                                                    vaultTopUpDetail?.total_amount_aed
                                                                )?.toFixed(2))}
                                                            </Typography>
                                                        </Cell>
                                                    </Row>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Box>
                                </Grid>
                            </Grid>

                            <Grid
                                container
                                spacing={2}
                                alignItems={"center"}
                                justifyContent={"space-between"}
                            >
                                <Grid item xs={10}>
                                    <Typography variant="body1" sx={{ mb: 2 }}>
                                        PLEASE READ CAREFULLY THE T&C:
                                    </Typography>
                                    <Box
                                        sx={{
                                            fontWeight: 500,
                                            fontSize: "12px",
                                        }}
                                    >
                                        <Typography
                                            variant="body2"
                                            className={classes.text}
                                            sx={{ mb: 1 }}
                                        >
                                            1- Kindly check or review the invoice/voucher and cash
                                            before leaving the counter.
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            className={classes.text}
                                            sx={{ mb: 1 }}
                                        >
                                            2- Our establishment will not be responsible for any
                                            delay, mistakes, or omissions in transmission.
                                        </Typography>{" "}
                                        <Typography
                                            variant="body2"
                                            className={classes.text}
                                            sx={{ mb: 1 }}
                                        >
                                            3- Our liability is limited to making all necessary
                                            amendments in the shortest possible time.
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item md={2}>
                                    <Box sx={{ mt: 3 }}>
                                        <QRCodeCanvas
                                            value={
                                                window.location.origin +
                                                `/vaulttopup-preview/${btoa(
                                                    "vaulttopup-" + vaultTopUpDetail?.id
                                                )}`
                                            }
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    )}
                    <Box sx={{ pl: 4, pr: 3, py: 1, bgcolor: Colors.primary }}>
                        <Typography
                            variant="caption"
                            sx={{ color: Colors.white, fontFamily: FontFamily.openSans }}
                        >
                            Customer care Contact: Mohammed husni - +971523195682 (Arabic &
                            English ) Ardamehr Shoev - +971545836028 (English ,Arabic, Tajik &
                            Farsi)
                        </Typography>
                        <Typography
                            variant="caption"
                            sx={{ color: Colors.white, fontFamily: FontFamily.openSans }}
                        >
                            Ravin abdul kareem - +971528293801 (Kurdish , Arabic & English)
                            Magsat Gylyjov - +97158666403 (Turken , Russian & English)
                        </Typography>
                    </Box>
                </Box>
            </PDFExport>
        </Container>
    );
}

export default TopUpVoucher;
